interface AudioSessionable {
  audioSession: {
    type: AudioSessionType;
  };
}

type AudioSessionType =
  | 'auto'
  | 'playback'
  | 'transient'
  | 'transient-solo'
  | 'ambient'
  | 'play-and-record';

/**
 * This is a new API that only currently exists in Safari (macOS/iOS). The
 * intent is that it allows the application to specify how it wants the audio
 * mixed and hardware to behave. See
 * https://github.com/w3c/audio-session/blob/main/explainer.md for more. There
 * is also a `state` corresponding `onstatechange` event interface, but those
 * are currently (as of Feb 2025) behind a Safari feature flag:
 * - https://searchfox.org/wubkat/rev/446d00e44d23eba2d8c1c1c92bf0171472dee385/Source/WebCore/Modules/audiosession/DOMAudioSession.idl#50-51
 *
 * As of 2025/02/04 & iOS 18.1.1, setting the property does have effects on iOS
 * Safari, but these effects are order-dependent:
 * - auto: the default. When calling `getUserMedia` on mobile safari, this tends
 *   to pick the phone's built-in mic and speakers over any wireless
 *   headphones/earbuds. But it's a 50/50 chance depending on various conditions
 *   like when audio was last played and from what app. For wired headphones
 *   with an inline mic, it tends to use the phone microphone but still route
 *   audio output through the headphones! Once the mic is released, sometimes
 *   playing a video or audio _after_ waiting a few seconds (the internal audio
 *   session needs to die) will route audio back through the connected device,
 *   but it's a gamble.
 * - play-and-record: If this is active _before_ calling `getUserMedia`, then
 *   it's still a 50/50 chance whether you get the device hardware or the
 *   headphones hardware as your IO. When calling `getUserMedia`, then setting
 *   `play-and-record`, this reliably kicks safari into routing to the connected
 *   device (earbuds, headphones, etc) for input and output. There is a
 *   noticeable output quality drop (everything usually gets mixed to mono) when
 *   using wireless technology due to bandwidth limitations. That quality drop
 *   will persist even _after_ the microphone has been released. Sometimes
 *   playing a new source of audio will automatically restore the quality, but
 *   it cannot be relied upon.
 * - playback: If you set this before calling `getUserMedia`, Safari will throw
 *   an error as this mode is incompatible with mic input in general. But this
 *   value is still extremely useful. Once the mic stream has been released,
 *   momentarily setting this value will kick iOS to restore the degraded audio
 *   quality (and routing) caused by `play-and-record` (and having the mic
 *   active in general) on wireless tech. It's best to then immediately set the
 *   value back to `auto` so that other code can reliably call `getUserMedia` in
 *   the future.
 *
 * Another quirky behavior I've encountered is that if you're using headphones
 * while audio is playing, then `getUserMedia` is called, you're going to start
 * to hear that audio through the phone speaker, whether you close the stream
 * immediately or not. Or call `getUserMedia` again. This has rammifications if
 * audio is meant to be playing before or during a permissions check. Only once
 * you either kick the audio subsystem by setting `audioSession.type =
 * 'playback'` (remember to set it back to `auto` afterwards!) _or_ wait an
 * unspecified duration after audio/video ends will it route back through the
 * headset. Presumably what's happening is that internally there truly is an
 * audio session, and it expires/destructs after no audio is needed for a while.
 * When a new one is created, it defaults to `auto` so the cycle can begin anew.
 *
 * When testing these APIs,
 * https://github.com/Narvii/lunapark/compare/drew/safari-audio-routing-test?expand=1
 * is useful (two separate routes there).
 *
 * If in the future, the above behavior no longer holds true, then the only way
 * I have found that reliably uses the active external device is to present the
 * choice to the user:
 * - `await getUserMedia()`, grab the MediaStreamTrack's DeviceID, then close
 *   the stream. This acquires permissions to list devices.
 * - `enumerateDevices()` and present a list of named input devices to the user,
 *   highlighting the Device ID you received previously.
 * - Allow the user to choose a new Device ID, and pass it to the next call to
 *   `getUserMedia()`. This seems to always kick Safari into using that device
 *   for both input _and_ output.
 */
export function audioSessionSetType(type: AudioSessionType) {
  if ('audioSession' in navigator) {
    (navigator as AudioSessionable).audioSession.type = type;
  }
}

/**
 * Use this to immediately restore degraded audio quality due to the use of a
 * previously open wireless microphone on iOS. Only has an effect when all
 * microphone streams are closed/inactive (i.e. the microphone is not in use).
 * @see audioSessionSetType
 */
export function audioSessionResetQuality() {
  audioSessionSetType('playback');
  audioSessionSetType('auto');
}

/**
 * Call getUserMedia and immediately close the stream, just to receive
 * permissions. Handles AudioSession API for iOS. @see
 * audioSessionSetType
 */
export async function audioSessionGetMicAccess(deviceId?: string) {
  audioSessionSetType('auto');

  let stream: MediaStream;
  if (deviceId) {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: { exact: deviceId } },
    });
  } else {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  }

  // Safari requires all tracks be explicitly stopped in order to release the
  // device!
  stream.getAudioTracks().forEach((track) => track.stop());
}

/**
 * Returns a microphone stream while managing AudioSession API for optimal iOS
 * behavior. @see audioSessionSetType
 */
export async function audioSessionGetMic(deviceId?: string) {
  audioSessionSetType('auto');
  let stream;
  if (deviceId) {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: { exact: deviceId } },
    });
  } else {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  }
  audioSessionSetType('play-and-record');
  return stream;
}

/**
 * Execute a function that presumably, eventually, calls `getUserMedia` while
 * managing AudioSession API for optimal iOS behavior.
 * @see audioSessionSetType
 */
export async function audioSessionExec<T>(
  accessor: () => Promise<T>
): Promise<T> {
  // Reset the audio session before calling getUserMedia
  audioSessionSetType('auto');
  const result = await accessor();
  // Force iOS Safari to reroute audio back to the connected device (if any)
  audioSessionSetType('play-and-record');
  return result;
}
